import styled from 'styled-components'
import background from "../assets/background.jpg";
import Home from "../pages/home";

const WrapperApp = styled.div`
    height: 100%;
`
const BackgroundBox = styled.div`
	height: 100%;
	background-image: url(${background});
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
`
function App() {
    return ( 
        <WrapperApp>
            <BackgroundBox>
				<Home />
			</BackgroundBox>
        </WrapperApp>
    );
}

export default App;