import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import bg1 from "../../assets/bg1.jpg";
import bg2 from "../../assets/bg2.jpg";
import bg3 from "../../assets/bg3.jpg";
import bg4 from "../../assets/bg4.jpg";
import bg5 from "../../assets/bg5.jpg";
import bg6 from "../../assets/bg6.jpg";
import bg7 from "../../assets/bg7.jpg";
import bg8 from "../../assets/bg8.jpg";
import bg9 from "../../assets/bg9.jpg";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import character1 from "../../assets/character1.jpg";
import character2 from "../../assets/character2.jpg";
import character3 from "../../assets/character3.jpg";
import al1 from "../../assets/al1.jpg";
import al2 from "../../assets/al2.jpg";
import al3 from "../../assets/al3.jpg";
import al4 from "../../assets/al4.jpg";
import al5 from "../../assets/al5.jpg";
import al6 from "../../assets/al6.png";
import al7 from "../../assets/al7.jpg";
import al8 from "../../assets/al8.jpg";
import al9 from "../../assets/al9.jpg";
import al10 from "../../assets/al10.jpg";
import al11 from "../../assets/al11.jpg";
import al12 from "../../assets/al12.jpg";
import al13 from "../../assets/al13.jpg";
import al14 from "../../assets/al14.jpg";
import al15 from "../../assets/al15.jpg";
import al16 from "../../assets/al16.jpg";
import al17 from "../../assets/al17.jpg";
import douyin from "../../assets/logo/douyin.png";
import tiktok from "../../assets/logo/tiktok.jpeg";
import face from "../../assets/logo/face.jpg";
import youtube from "../../assets/logo/youtube.jpg";
import red from "../../assets/logo/red.png";
import shiph from "../../assets/logo/shiph.jpg";
import bili from "../../assets/logo/bili.jpg";
import ins from "../../assets/logo/ins.png";
import reddit from "../../assets/logo/reddit.png";
import { Text } from "../../components/text";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 60px 32px 32px;
    overflow-y: scroll;
    box-sizing: border-box;
	@media screen and (max-width: 560px) {
		padding: 20px 10px 10px;
	}
`
const WrapperIn = styled.div`
    max-width: 1000px;
    margin: 10px auto;
    box-sizing: border-box;
`
const ContentBox = styled.div`
    width: 100%;
    border: 1px solid #898786f0;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 64px;
    position: relative;
	@media screen and (max-width: 560px) {
		margin-bottom: 10px;
	}
`
const Content1 = styled.div`
    position: relative;
    padding: 50px 60px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
	@media screen and (max-width: 560px) {
		padding: 16px 10px;
	}
`
const Content2 = styled(Content1)`
	display: flex;
	flex-direction: column;
	align-self: center;
`
const Content3 = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
	min-height: 440px;
	@media screen and (max-width: 560px) {
		flex-direction: column;
		&.reverse{
			flex-direction: column-reverse;
		}
	}
`
const Content5 = styled(Content1)`
	display: flex;
	flex-direction: column;
	align-self: center;
`
const Content4 = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
	grid-template-columns: 1fr;
    grid-template-rows: minmax(200px, auto) 1fr;
    grid-template-areas:
        "accent"
        "body";
`
const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
`;
const Mask = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0d0a2ccc;
`
const MaskFilter = styled.div`
    position: absolute;
    inset: 0px;
    box-shadow: 0 0.25em 0.375em -0.0625em rgba(0, 0, 0, 0.1),0 0.125em 0.25em -0.0625em rgba(0, 0, 0, 0.06),0 0 0 0.0625em rgba(255, 255, 255, 0.16);
    border-radius: 0.75em;
    background-color: #0d0a2cbf;
    backdrop-filter: blur(20px) saturate(170%);
`
const Margin = styled.div`
    margin-top: ${({t}) => t? t + 'px': '0px'};
    margin-bottom: ${({b}) => b? b + 'px': '0px'};
`
const Character = styled.div`
    display: grid;
    gap: 3em 1.5em;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    position: relative;
	margin-top:30px;
`
const CharacterBox = styled.div`
    width:100%;
`
const BackgroundImg = styled.div`
	display: flex;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${({img}) => img});
    background-position: 51.72% 32.22%;
    width: 100%;
    height: 100%;
`
const Sculpture = styled(BackgroundImg)`
    aspect-ratio: 1.618 / 1;
    border-radius: 10px;
`
const AnliImg = styled(BackgroundImg)`
	aspect-ratio: 1 / 1;
	border-radius: 10px;
`
const ImgWidth = styled.div`
	width: 60%;
	@media screen and (max-width: 560px) {
		width: 100%;
		height: 446px;
	}
`
const ImgWidth2 = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`
const ImgWidth3 = styled.div`
	width: 36px;
	height: 36px;
	margin: 0 auto;
`
const ImgWidth4 = styled.div`
	position: relative;
	width: 100%;
`
const ImgStyle = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
`
const FlowPath = styled.div`
	margin: 60px 0px 0px;
	border-top: 3px solid #552c86ff;
	@media screen and (max-width: 680px) {
		margin: 0px 0px 0px 40px;
		border-top: none;
		border-left: 3px solid #552c86ff;
		padding: 10px 0;
	}
`
const FlowPathContent = styled.div`
	display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: -22px;
	gap: 30px;
	@media screen and (max-width: 680px) {
		display: flex;
    	flex-wrap: wrap;
    	gap: 20px;
    	flex-direction: column;
		margin-top: 0px;
		margin-left: -20px;
	}
`
const FlowPathItem = styled.div`
	flex: 1;
	@media screen and (max-width: 680px) {
		display: flex;
    	align-items: baseline;
    	gap: 10px;
    	text-align: justify;
	}
`
const OrderBox = styled.div`
	width: 20px;
	height: 40px;
	border-radius: 6px;
	border: 1px solid #552c86ff;
	background-color: #3c136dff;
	background-image:linear-gradient(121deg, #3c136dff 10%, #26136cff 90%);
	padding: 0px 12px;
	display: flex;
    align-items: center;
	justify-content: center;
	margin: 0 auto;
	@media screen and (max-width: 680px) {
		width: 10px;
    	height: 30px;
	}
`
const AnLi = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	margin-top:30px;
`
const AnliItme = styled.div`
	box-sizing: border-box;
	max-width: calc(33.33% - 20px);
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: calc(33.33% - 20px);
	@media screen and (max-width: 860px) {
		max-width: calc(50% - 20px);
		flex-basis: calc(50% - 20px);
	}
	@media screen and (max-width: 660px) {
		max-width: 100%;
		flex-basis: 100%;
	}
`
const FlexText = styled.div`
	display: flex;
	gap: 10px;
`
const Peizhi = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`
const PeizhiItme = styled.div`
	display: flex;
	align-items: baseline;
	gap: 10px;
	box-sizing: border-box;
	max-width: calc(50% - 20px);
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: calc(50% - 20px);
	@media screen and (max-width: 860px) {
		max-width: 100%;
		flex-basis: 100%;
	}
`
const LogoBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`
const LogoItme = styled.div`
	
`
const LogoImg = styled.img`
	object-fit: cover;
	border-radius: 10px;
	width: auto;
	height: 100px;
	@media screen and (max-width: 560px) {
		height: 58px;
	}
`

function Home() {
    const character = [
        {
            img: character1,
            name: '王瑛',
            position: 'CEO',
            describe: '标签：持续跨界创业者，十年营销操盘手，AI营销理论开创者'
        },
        {
            img: character2,
            name: '陈正',
            position: 'CTO',
            describe: '标签：港中大硕士，二次元游戏领域开发与投资专家，AI应用开发专家'
        },
        {
            img: character3,
            name: '邹哲',
            position: 'CMO',
            describe: '标签：95后全域媒体操盘手'
        },
    ]
	const icon = [
		{
			icon: icon1,
			title: '用户',
			text1: '儿童摄影机构',
			text2: '教育培训机构',
			text3: '以女性及儿童为客户的机构',
		},
		{
			icon: icon2,
			title: '价值',
			text1: '用AI写真服务私域',
			text2: '用AI写真建立客户联系',
			text3: '为机构建立流量护城河',
		},
		{
			icon: icon3,
			title: '支持',
			text1: 'AI系列课程实训系统',
			text2: '一站式AI写真工具系统',
			text3: 'AI艺术交易系统',
		}
	]
	const flowPath = [
		{title: '脚本', text: '使用AI文本模型批量出文案脚本'},
		{title: '图片', text: '使用MJ/SD等图像模型批量生成图片'},
		{title: '视频', text: '使用Runway、Pika、Genmo生成视频'},
		{title: '剪辑', text: '使用AI智能剪辑一分钟剪辑1000条视频'},
		{title: '矩阵', text: '使用矩阵工具管理全域千号并用AI客服自动回复'},
	]
	const logo = [
		{img: douyin, alt:'抖音'},
		{img: tiktok, alt:'tiktok'},
		{img: face, alt:'faceBook'},
		{img: youtube, alt:'youtube'},
		{img: red, alt:'小红书'},
		{img: shiph, alt:'视频号'},
		{img: bili, alt:'bilibili 哔哩哔哩'},
	]
	const logo2 = [
		{img: ins, alt:'instagram'},
		{img: reddit, alt:'reddit'},
	]
	const anli = [
		{img: al1, title: '抖音', text: 'AI小和尚矩阵项目，单月粉丝破百万，私域单产品变现40W'},
		{img: al2, title: '小红书', text: 'AI穿搭账号，皮肤变现月入10W+'},
		{img: al3, title: '小红书', text: 'AI创意摄影账号，小红书接单月入5W+'},
		{img: al4, title: '抖音', text: 'AI国学号，从图到视频、文案、剪辑全部由AI完成，小黄车挂单单月3万'},
		{img: al5, title: '抖音', text: '疗愈音乐，文生图、图生视频，批量剪辑'},
		{img: al6, title: '视频号', text: '时尚科技虚拟偶像，数字人芭比，多语言讲述科技界的故事。'},
	]
	const anli2 = [
		{img: al7, title: 'B站', text: '“嫣汐”歌姬主播，使用AI引擎制作的多风格AI虚拟歌手，开播日均收入800元。'},
		{img: al8, title: 'B站', text: '“蓓塔”聊天主播，使用AI引擎制作的傲娇风格聊天主播，开播日均收入1000元。'},
		{img: al9, title: 'B站', text: '使用AI引擎开发的直播游戏，玩家通过说话训练并指挥军队彼此对抗，充值可召唤强大兵种，日均收入1590元'},
		{img: al10, title: '实体门店', text: '实体门店多场景AI自动直播', text2:'智能剪辑一分钟生成千条视频', text3: '矩阵销售获客发布管理系统'},
		{img: al11, title: '文旅', text: '文旅实景AI自动走播', text2: '智能剪辑一分钟生成千条视频', text3: '矩阵销售获客发布管理系统'},
	]
	const anli3 = [
		{img: al12, title: '游戏', text: '在 INSTAGRAM 上进行游戏互动，在竞 品官网、游戏论坛及关键词标签中推荐 APP ，帮助 APP 获 得 百 万 次 曝 光 ， AUTOPILOT 运营期间，整体 APP 下载 量同比提升 15%'},
		{img: al13, title: '咖啡机', text: '在 REDDIT 、YOUTUBE 、 INSTA GRAM 上进行大量的咖啡频道互动讨论，直接 转化超过 20 万美金，间接转化百万 +'},
		{img: al14, title: '留学', text: '通过AUTOPILOT 在知乎、 B 站、小红书上进行行业经验分享，避坑 指南等，通过发帖、 回复进行口碑营销， 帮助小红书账号涨粉 15 万，直接带来客 户转化每月 + 9 5 %'},
	]
	const anli4 = [
		{img: al15, title: '真人头像' },
		{img: al16, title: 'AI漫改' },
		{img: al17, title: 'AI数字人' },
	]
	const pzlc = [
		{title: '确定人设', text: '确定机器人的身份、职业、语 气等信息，输入产品相关信息 资料，让ChatGPT进行学习。'},
		{title: '确定关键词', text: '根据产品品牌、品类、功能亮 点及竞品情况设定关键词。'},
		{title: '设定工作流', text: '确定机器人排序原则，等待时 间，搜索筛选逻辑，评论、私 信回复上限。'},
		{title: '分配工作平台', text: '根据实际需求，设定机器人在 不同的时间段具体在哪个平台 进行工作。'},
	]
    return(
        <Wrapper>
            <WrapperIn>
                <ContentBox>
                    <ImageBg src={bg1} />
                    <Mask />
                    <Content1>
                        {/* <Margin t={30} b={120}>
                            <Text color={'#F2F0F4'} bold={true} size={46}>Artist</Text>
                        </Margin> */}
                        <Margin b={20}>
                            <Text color={'#F2F0F4'} bold={true} size={46}>
                                用生成式人工智能改变企业营销方式
                            </Text>
                        </Margin>
                        <Text color={'#F2F0F4'} size={20}>
                            Transform Enterprise Marketing with Generative AI
                        </Text>
                        <Margin t={80} b={50}>
                            <Text color={'#F2F0F4'} size={20}>
                                中科数字未来（深圳）科技有限公司
                            </Text>
                        </Margin>
                    </Content1>
                </ContentBox>
                <ContentBox>
                    <MaskFilter />
                    <Content2>
                        <Text color={'#F2F0F4'} size={40} justify={true}>创始人团队</Text>
                        <Character>
                            {
                                character.map(item => (
                                    <CharacterBox>
                                        <Margin b={20}>
                                            <Sculpture img={item.img} loading="lazy"/>
                                        </Margin>
                                        <Text color={'#F2F0F4'} size={28} justify={true}>{item.name}</Text>
                                        <Text color={'#F2F0F4'} size={18} justify={true}>{item.position}</Text>
                                        <Text color={'#F2F0F4'} size={18} justify={true}>
                                            {item.describe}
                                        </Text>
                                    </CharacterBox>
                                ))
                            }
                        </Character>
                    </Content2>
                </ContentBox>
                <ContentBox>
                    <MaskFilter />
                    <Content3>
						<ImgWidth>
							<BackgroundImg img={bg2} loading="lazy"/>
						</ImgWidth>
						<Content2>
							<div>
								<Margin b={30}>
									<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.1  数字引流</Text>
								</Margin>
								<Margin b={30}>
									<Text color={'#F2F0F4'} size={22} justify={true}>
										用AI写真工具  成为流量中心
									</Text>
								</Margin>
								<Margin b={20}>
									<Text color={'#F2F0F4'} size={18} justify={true}>
										如果你想链接客户，
									</Text>
								</Margin>
								<Margin b={20}>
									<Text color={'#F2F0F4'} size={18} justify={true}>
										如果你想增加会员粘性，
									</Text>
								</Margin>
								<Margin b={20}>
									<Text color={'#F2F0F4'} size={18} justify={true}>
										如果你想让第一次加微信的陌生朋友喜欢上你，
									</Text>
								</Margin>
								<Text color={'#F2F0F4'} size={18} justify={true}>
									毫无疑问，AI数字引流将是你2024年唯一选择！
								</Text>
							</div>
						</Content2>
                    </Content3>
                </ContentBox>
                <ContentBox>
					<MaskFilter />
					<Content4>
						<ImgWidth2>
							<BackgroundImg img={bg3} loading="lazy"/>	
						</ImgWidth2>
						<Content2>
							<Character>
								{
									icon.map(item => (
										<CharacterBox>
											<Margin b={20}>
												<ImgWidth3>
													<ImgStyle src={item.icon} loading="lazy"/>
												</ImgWidth3>
											</Margin>
											<Text color={'#F2F0F4'} size={28}>{item.title}</Text>
											<Text color={'#F2F0F4'} size={16}>{item.text1}</Text>
											<Text color={'#F2F0F4'} size={16}>{item.text2}</Text>
											<Text color={'#F2F0F4'} size={16}>{item.text3}</Text>
										</CharacterBox>
									))
								}
							</Character>
						</Content2>
					</Content4>
                </ContentBox>
				<ContentBox>
                    <MaskFilter />
                    <Content3>
						<ImgWidth>
							<BackgroundImg img={bg4} loading="lazy"/>
						</ImgWidth>
						<Content5>
							<Margin b={40}>
								<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.2 AI矩阵带货</Text>
							</Margin>
							<Margin b={30}>
								<Text color={'#F2F0F4'} size={18} justify={true}>
									图文带货、短视频带货
								</Text>
							</Margin>
								<Text color={'#F2F0F4'} size={22} justify={true}>
									星火计划：用万千账号点燃燎原之火
								</Text>
						</Content5>
                    </Content3>
                </ContentBox>
                <ContentBox>
					<MaskFilter />
					<Content2>
						<Margin b={20}>
                    	    <Text color={'#F2F0F4'} size={40} justify={true}>全域媒体</Text>
						</Margin>
						<Margin b={20}>
							<LogoBox>
								{
									logo.map(item => (
										<LogoItme>
											<LogoImg src={item.img} alt={item.alt} loading="lazy"/>
										</LogoItme>
									))
								}
							</LogoBox>
						</Margin>
						<LogoBox>
							{
								logo2.map(item => (
									<LogoItme>
										<LogoImg src={item.img} alt={item.alt} loading="lazy"/>
									</LogoItme>
								))
							}
						</LogoBox>
					</Content2>
                </ContentBox>
                <ContentBox>
					<MaskFilter />
					<Content2>
						<Margin b={20}>
                    	    <Text color={'#F2F0F4'} size={40} justify={true}>AI在图文短视频领域可驱动内容</Text>
						</Margin>
						<FlowPath>
							<FlowPathContent>
								{
									flowPath.map((item, index) => (
										<FlowPathItem>
											<div>
												<OrderBox>
													<Text className={'margin'} color={'#F2F0F4'} size={20}>
														{index + 1}
													</Text>
												</OrderBox>
											</div>
											<div>
												<Margin t={20} b={10}>
													<Text color={'#F2F0F4'} size={22}>{item.title}</Text>
												</Margin>
												<Text color={'#F2F0F4'} size={18}>{item.text}</Text>
											</div>
										</FlowPathItem>
									))
								}
							</FlowPathContent>
						</FlowPath>
					</Content2>
                </ContentBox>
                <ContentBox>
					<MaskFilter />
					<Content2>
						<Text color={'#F2F0F4'} size={40} justify={true}>案例</Text>
						<AnLi>
							{
								anli.map(item => (
									<AnliItme>
										<ImgWidth4>
											<AnliImg img={item.img} loading="lazy"/>
										</ImgWidth4>
										<Text color={'#F2F0F4'} size={22} justify={true}>{item.title}</Text>
										<Text color={'#F2F0F4'} size={18} justify={true}>{item.text}</Text>
									</AnliItme>
								))
							}
						</AnLi>
					</Content2>
                </ContentBox>
				<ContentBox>
                    <MaskFilter />
                    <Content3>
						<ImgWidth>
							<BackgroundImg img={bg5} loading="lazy"/>
						</ImgWidth>
						<Content5>
							<Margin b={40}>
								<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.3 AI直播</Text>
							</Margin>
							<Margin b={30}>
								<Text style={{whiteSpace: 'pre-wrap'}} color={'#F2F0F4'} size={18} justify={true}>
									AI数字人直播、AI无人直播、AI走播
								</Text>
							</Margin>
							<Text color={'#F2F0F4'} size={22} justify={true}>
								区别于市场上同类名称的产品，我们采取自研AI引擎，真正带动数字人或者声音模型，
								用AI实时互动的方式来做直播，而市场上其他数字人或者无人直播只是用提前录制脚本方式，
								不可以实时互动，所谓的AI功能只是在录入脚本的时候可以调用AI功能来辅助创作。
							</Text>
						</Content5>
                    </Content3>
                </ContentBox>
				<ContentBox>
					<MaskFilter />
					<Content2>
						<Text color={'#F2F0F4'} size={40} justify={true}>案例</Text>
						<AnLi>
							{
								anli2.map(item => (
									<AnliItme>
										<ImgWidth4>
											<AnliImg img={item.img} loading="lazy"/>
										</ImgWidth4>
										<Text color={'#F2F0F4'} size={22} justify={true}>{item.title}</Text>
										<Text color={'#F2F0F4'} size={18} justify={true}>{item.text}</Text>
										{
											item.text2 && <Text color={'#F2F0F4'} size={18} justify={true}>{item.text2}</Text>
										}
										{
											item.text3 && <Text color={'#F2F0F4'} size={18} justify={true}>{item.text3}</Text>
										}
									</AnliItme>
								))
							}
						</AnLi>
					</Content2>
                </ContentBox>
				<ContentBox>
                    <MaskFilter />
                    <Content3>
						<ImgWidth>
							<BackgroundImg img={bg6} loading="lazy"/>
						</ImgWidth>
						<Content5>
							<Margin b={40}>
								<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.4 种草机器人</Text>
							</Margin>
							<Text color={'#F2F0F4'} size={22} justify={true}>
								Autopilot是根据过往合作上百个品牌需求出发，打造的智能化线上种草营销系统，通过打通智能机器人 + 
								ChatGPT，实现在国内外社交媒体智能化用户沟通、舆论引导、 品牌种草等口碑传播。
							</Text>
							<Text color={'#F2F0F4'} size={22} justify={true}>
								自动抓取品牌的关键词、标题、内容和评论，并给予每一个机器 人不同的人物设定， 实现拟人化“真实互动评论”。
								给品牌带来持 续长期的正向口碑以及络绎不绝的高意向购买者。
							</Text>
						</Content5>
                    </Content3>
                </ContentBox>
				<ContentBox>
                    <ImageBg src={bg6} loading="lazy"/>
                    <Mask />
                    <Content2>
						<Text color={'#F2F0F4'} size={40} justify={true}>配置流程</Text>
                        <Peizhi>
							{
								pzlc.map((item, index) =>(
									<PeizhiItme>
										<div>
											<OrderBox>
												<Text className={'margin'} color={'#F2F0F4'} size={20}>
													{index + 1}
												</Text>
											</OrderBox>
										</div>
										<div>
											<Margin t={20} b={10}>
												<Text color={'#F2F0F4'} size={22} justify={true}>{item.title}</Text>
											</Margin>
											<Text color={'#F2F0F4'} size={18} justify={true}>{item.text}</Text>
										</div>
									</PeizhiItme>
								))
							}
						</Peizhi>
                    </Content2>
                </ContentBox>
				<ContentBox>
					<MaskFilter />
					<Content2>
						<Text color={'#F2F0F4'} size={40} justify={true}>案例</Text>
						<AnLi>
							{
								anli3.map(item => (
									<AnliItme>
										<ImgWidth4>
											<AnliImg img={item.img} loading="lazy"/>
										</ImgWidth4>
										<Text color={'#F2F0F4'} size={22} justify={true}>{item.title}</Text>
										<Text color={'#F2F0F4'} size={18} justify={true}>{item.text}</Text>
									</AnliItme>
								))
							}
						</AnLi>
					</Content2>
                </ContentBox>
				<ContentBox>
                    <MaskFilter />
                    <Content3 className={'reverse'}>
						<Content5>
							<Margin b={40}>
								<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.5 AI电商工具</Text>
							</Margin>
							<Margin b={10}>
								<Text color={'#F2F0F4'} size={22} justify={true}>
									电商图一键生成：利用公共网站或训练自有模型
								</Text>
							</Margin>
							<Margin b={50}>
								<Text color={'#F2F0F4'} size={16} justify={true}>
									对于服装企业，省模特、省拍摄、省道具，省人工，降本增效必备
								</Text>
							</Margin>
							<Margin b={10}>
								<Text color={'#F2F0F4'} size={22} justify={true}>
									AI客服：多语言、24小时服务
								</Text>
							</Margin>
							<Margin b={50}>
								<Text color={'#F2F0F4'} size={16} justify={true}>
									特别对于跨境电商企业，小语种也毫无担忧
								</Text>
							</Margin>
						</Content5>
						<ImgWidth>
							<BackgroundImg img={bg7} loading="lazy"/>
						</ImgWidth>
                    </Content3>
                </ContentBox>
				<ContentBox>
					<MaskFilter />
					<Content5>
						<Margin b={20}>
							<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.6 AI工具系统</Text>
						</Margin>
						<Margin b={10}>
							<Text color={'#F2F0F4'} size={18} justify={true}>
								自研AI工具系统，包含文本、绘画等大模型，支持企事业单位定制开发。
							</Text>
						</Margin>
						<ImgStyle src={bg8} loading="lazy"/>
					</Content5>
                </ContentBox>
                <ContentBox>
					<MaskFilter />
					<Content5>
						<Margin b={20}>
							<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.7 创始人数字IP打造</Text>
						</Margin>
						<Margin b={10}>
							<Text color={'#F2F0F4'} size={18} justify={true}>
								创始人做IP是必经之路，同步将创始人IP数字化改造，能创造出更多的有意思和降本增效的场景，
								我们在创始人IP打造，无论是真人打造还是在数字化和虚拟场景上，都有独特的开发和赋能资源。
							</Text>
						</Margin>
						<AnLi>
							{
								anli4.map(item => (
									<AnliItme>
										<ImgWidth4>
											<AnliImg img={item.img} loading="lazy"/>
										</ImgWidth4>
										<Text color={'#F2F0F4'} size={22} justify={true}>{item.title}</Text>
									</AnliItme>
								))
							}
						</AnLi>
					</Content5>
                </ContentBox>
				<ContentBox>
					<MaskFilter />
					<Content5>
						<Margin b={20}>
							<Text color={'#D8AFF8'} size={46} justify={true} bold={true}>NO.8  数字管家AI孔明</Text>
						</Margin>
						<Margin b={10}>
							<ImgStyle src={bg9} loading="lazy"/>
						</Margin>
						<Margin b={10}>
							<Text color={'#F2F0F4'} size={18} justify={true}>
								“孔明”智能管家允许用户像视频聊天一样，使用自然语言交流，也能像真人一样迅速理解和解决问题。
								虽然使用起来就像跟真人助手说话一样简单，但强大的AI内核和大规模的应用集成让“孔明”
								足以胜任您的军师。不仅可以调用您服务器上原有的软件和素材，更可以直接调用翻译插件Speak，
								购物插件Klarna，差旅插件Expedia，计算器Wolfram和表格文档处理插件Zapier等能够用语言理解
								需求的先进应用，让您如同古代主公般，在谈笑间完成工作。
							</Text>
						</Margin>
						<Margin b={10}>
							<Text color={'#F2F0F4'} size={18} justify={true}>
								孔明数字管家支持企事业单位定制开发。
							</Text>
						</Margin>
					</Content5>
                </ContentBox>
            </WrapperIn>
        </Wrapper>
    )
}

export default Home;